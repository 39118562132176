import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_ROLE",
  };
};

// Save role

export const saveRole = (data) => {
  return function (dispatch) {
    dispatch(saveRoleStart());
    axios
      .post("roles", data)
      .then((response) => {
        const result = response.data;
        dispatch(saveRoleSuccess(result));
      })
      .catch((err) => {
        const error = { ...err };
        dispatch(saveRoleError(error));
      });
  };
};

export const saveRoleStart = () => {
  return {
    type: "SAVE_ROLE_START",
  };
};

export const saveRoleSuccess = (result) => {
  return {
    type: "SAVE_ROLE_SUCCESS",
    role: result,
  };
};

export const saveRoleError = (error) => {
  return {
    type: "SAVE_ROLE_ERROR",
    error,
  };
};

// Load Role

export const loadRole = (query = "") => {
  return function (dispatch) {
    dispatch(loadRoleStart());
    axios
      .get("roles?" + query)
      .then((response) => {
        const datas = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadRoleSuccess(datas));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        dispatch(loadRoleError(errorBuild(error)));
      });
  };
};

export const loadRoleStart = () => {
  return {
    type: "LOAD_ROLE_START",
  };
};

export const loadRoleSuccess = (data, pagination) => {
  return {
    type: "LOAD_ROLE_SUCCESS",
    data,
    pagination,
  };
};

export const loadRoleError = (error) => {
  return {
    type: "LOAD_ROLE_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_PAGINATION",
    pagination,
  };
};

export const deleteMultRole = (ids) => {
  return function (dispatch, getState) {
    dispatch(deleteMultStart());
    axios
      .delete("roles/delete", { params: { id: ids } })
      .then((response) => {
        const deleteRole = response.data.data;
        dispatch(deleteRoleSuccess(deleteRole));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deleteRoleError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_ROLE_START",
  };
};

export const deleteRoleSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_ROLE_SUCCESS",
    deleteRole: deleteData,
  };
};

export const deleteRoleError = (error) => {
  return {
    type: "DELETE_MULT_ROLE_ERROR",
    error,
  };
};

// GET ROLE

export const getInit = () => {
  return {
    type: "GET_ROLE_INIT",
  };
};

export const getRole = (id) => {
  return function (dispatch, getState) {
    dispatch(getRoleStart());
    axios
      .get("roles/" + id)
      .then((response) => {
        const role = response.data.data;
        dispatch(getRoleSuccess(role));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getRoleError(resError));
      });
  };
};

export const getRoleStart = () => {
  return {
    type: "GET_ROLE_START",
  };
};

export const getRoleSuccess = (role) => {
  return {
    type: "GET_ROLE_SUCCESS",
    role,
  };
};

export const getRoleError = (error) => {
  return {
    type: "GET_ROLE_ERROR",
    error,
  };
};

//UPDATE ROLE

export const updateRole = (id, data) => {
  return function (dispatch) {
    dispatch(updateRoleStart());
    axios
      .put(`roles/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updateRoleSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updateRoleError(resError));
      });
  };
};

export const updateRoleStart = () => {
  return {
    type: "UPDATE_ROLE_START",
  };
};

export const updateRoleSuccess = (result) => {
  return {
    type: "UPDATE_ROLE_SUCCESS",
    updateRole: result,
  };
};

export const updateRoleError = (error) => {
  return {
    type: "UPDATE_ROLE_ERROR",
    error,
  };
};

export const getCountRole = () => {
  return function (dispatch) {
    dispatch(getCountRoleStart());

    axios
      .get(`roles/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountRoleSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountRoleError(resError));
      });
  };
};

export const getCountRoleStart = () => {
  return {
    type: "GET_COUNT_ROLE_START",
  };
};

export const getCountRoleSuccess = (result) => {
  return {
    type: "GET_COUNT_ROLE_SUCCESS",
    orderCount: result,
  };
};

export const getCountRoleError = (error) => {
  return {
    type: "GET_COUNT_ROLE_ERROR",
    error,
  };
};
