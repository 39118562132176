import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Components
import PageTitle from "../../../Components/PageTitle";
import Loader from "../../../Components/Generals/Loader";

//Actions
import { tinymceAddPhoto } from "../../../redux/actions/imageActions";
import * as actions from "../../../redux/actions/roleActions";
import { Button, Form, Input, Switch, Tree } from "antd";
import * as newsCategoryAction from "../../../redux/actions/newsCategoryActions";
import * as mediaCategoryAction from "../../../redux/actions/mediaCategoryActions";
import * as menuAction from "../../../redux/actions/menuActions";
import * as positionAction from "../../../redux/actions/positionActions";
import * as footerMenuActions from "../../../redux/actions/footerMenuActions";
import { useCookies } from "react-cookie";
import { languageRender } from "src/lib/inputRegex";
import { convertFromdata } from "src/lib/handleFunction";
import { toastControl } from "src/lib/toasControl";

// Lib

const requiredRule = {
  required: true,
  message: "Тус талбарыг заавал бөглөнө үү",
};

const initialPermissions = {
  news: [
    "add",
    "edit",
    "delete",
    "advanced",
    "isActiveStatus",
    "isDraftStatus",
  ],
  media: [
    "add",
    "edit",
    "delete",
    "advanced",
    "isActiveStatus",
    "isDraftStatus",
  ],
  partner: ["add", "edit", "delete"],
  employee: ["add", "edit", "delete", "advanced"],
  book: ["add", "edit", "delete"],
  contact: ["view", "delete"],
  settings: ["view", "delete"],
  siteMenu: ["add", "edit", "delete"],
  sitePage: [
    "add",
    "edit",
    "delete",
    "advancedMenu",
    "advancedFooterMenu",
    "advancedEmployee",
  ],
};

const generateInitialState = () => {
  const initialState = {};
  Object.keys(initialPermissions).forEach((section) => {
    initialPermissions[section].forEach((action) => {
      initialState[`${section}_${action}`] = false;
    });
  });
  return initialState;
};

const Add = (props) => {
  const [form] = Form.useForm();
  const [cookies] = useCookies(["language"]);

  // Check Datas
  const [checkMenu, setCheckMenu] = useState([]);
  const [checkFooterMenu, setCheckFooterMenu] = useState([]);
  const [checkPosition, setCheckPosition] = useState([]);
  const [checkNewsCategories, setCheckNewsCategories] = useState([]);
  const [checkMediaCategories, setCheckMediaCategories] = useState([]);
  const [checkEmployee, setCheckEmployee] = useState([]);

  // Get Datsas
  const [footerMenus, setFooterMenus] = useState([]);
  const [menus, setMenus] = useState([]);
  const [positions, setPositions] = useState([]);
  const [newsCategories, setNewsCategories] = useState([]);
  const [mediaMenus, setMediaMenus] = useState([]);

  const [loading, setLoading] = useState(false);
  const [checkedDatas, setCheckedDatas] = useState(generateInitialState());

  const clear = () => {
    form.resetFields();
    setLoading(false);
  };

  const handleCheck = (value, type) => {
    setCheckedDatas((bcheck) => ({ ...bcheck, [type]: value }));
  };

  const init = () => {
    if (props.roleAccess !== "admin") {
      toastControl("error", "Хандах боломжгүй");
      props.history.goBack();
    }
    props.loadNewsCagegories();
    props.loadMediaCategories();
    props.loadPositions();
    props.loadMenus();
    props.loadFooterMenus();
    props.getRole(props.match.params.id);
  };

  const menuGenerateData = (categories) => {
    let datas = [];
    if (categories) {
      categories.map((el) => {
        datas.push({
          title: el[cookies.language] ? (
            languageRender(el, "name", cookies.language)
          ) : (
            <span className="red-color">
              {languageRender(el, "name", cookies.language)}
            </span>
          ),
          key: el._id,
          children: el.children && menuGenerateData(el.children),
        });
      });
    }

    return datas;
  };

  const createPermission = (
    prefix,
    values,
    categories,
    advancedField = false
  ) => ({
    add: values[`${prefix}_add`] || false,
    edit: values[`${prefix}_edit`] || false,
    delete: values[`${prefix}_delete`] || false,
    advanced: advancedField ? values[`${prefix}_advanced`] || false : false,
    categories: categories ? [...categories] : undefined,
  });

  useEffect(() => {
    init();
    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    if (props.categories) {
      const data = menuGenerateData(props.categories);
      setNewsCategories(data);
    }

    if (props.mediaCategories) {
      const data = menuGenerateData(props.mediaCategories);
      setMediaMenus(data);
    }
    if (props.positions) {
      const data = menuGenerateData(props.positions);
      setPositions(data);
    }
    if (props.menus) {
      const data = menuGenerateData(props.menus);
      setMenus(data);
    }
    if (props.footerMenus) {
      const data = menuGenerateData(props.footerMenus);
      setFooterMenus(data);
    }
  }, [
    props.categories,
    props.mediaCategories,
    props.positions,
    props.menus,
    props.footerMenus,
  ]);

  const handleAdd = async (values) => {
    const data = {
      name: values.name,
      premission: {
        news: {
          ...createPermission("news", values, checkNewsCategories, true),
          isActiveStatus: values.news_isActiveStatus || false,
          isDraftStatus: values.news_isDraftStatus || false,
        },
        media: {
          ...createPermission("media", values, checkMediaCategories, true),
          isActiveStatus: values.media_isActiveStatus || false,
          isDraftStatus: values.media_isDraftStatus || false,
        },
        partner: createPermission("partner", values),
        book: createPermission("book", values),
        employee: {
          ...createPermission("employee", values, [], true),
          positions: [...checkEmployee],
        },

        contact: {
          view: values.contact_view || false,
          delete: values.contact_delete || false,
        },
        settings: {
          view: values.settings_view || false,
          delete: values.settings_delete || false,
        },
        siteMenu: createPermission("siteMenu", values),
        sitePage: {
          ...createPermission("sitePage", values),
          advancedMenu: values.sitePage_advancedMenu || false,
          advancedFooterMenu: values.sitePage_advancedFooterMenu || false,
          advancedEmployee: values.sitePage_advancedEmployee || false,
          menu: [...checkMenu],
          footerMenu: [...checkFooterMenu],
          position: [...checkPosition],
        },
      },
    };
    // console.log(data);
    props.updateRole(props.match.params.id, data);
  };

  // Ямар нэгэн алдаа эсвэл амжилттай үйлдэл хийгдвэл энд useEffect барьж аваад TOAST харуулна
  useEffect(() => {
    toastControl("error", props.error);
  }, [props.error]);

  useEffect(() => {
    if (props.success) {
      toastControl("success", props.success);
      setTimeout(() => props.history.replace("/roles"), 2000);
    }
  }, [props.success]);

  useEffect(() => {
    if (props.role) {
      const updatedCheckedDatas = {};
      Object.keys(initialPermissions).forEach((section) => {
        initialPermissions[section].forEach((action) => {
          updatedCheckedDatas[`${section}_${action}`] =
            props?.role?.premission?.[section]?.[action] || false;
        });
      });

      setCheckedDatas(updatedCheckedDatas);
      setCheckNewsCategories(props?.role?.premission?.news?.categories);
      setCheckMediaCategories(props?.role?.premission?.media?.categories);
      setCheckEmployee(props?.role?.premission?.employee?.positions);
      setCheckMenu(props?.role?.premission?.sitePage?.menu);
      setCheckFooterMenu(props?.role?.premission?.sitePage?.footerMenu);
      setCheckPosition(props?.role?.premission?.sitePage?.position);

      form.setFieldsValue({
        ...props.role,
        ...updatedCheckedDatas,
      });
    }
  }, [props.role]);

  return (
    <>
      <div className="content-wrapper">
        <PageTitle name="Эрх шинэчлэх" />
        <div className="page-sub-menu"></div>
        <div className="content">
          <Form form={form} layout="vertical">
            {/* <Loader show={loading.visible}> {loading.message} </Loader> */}
            <div className="modal__items">
              <div className="modal__item full">
                <Form.Item
                  label="Эрхийн нэр"
                  name="name"
                  rules={[requiredRule]}
                >
                  <Input placeholder="Эрхийн нэр оруулна уу" />
                </Form.Item>
              </div>
              <div className="modal__item">
                <label>Нийтлэл</label>
                <div className="modal__item_box">
                  <p> Нийтлэл нэмэх </p>
                  <Form.Item name="news_add">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "news_add")}
                      checked={checkedDatas?.news_add}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Нийтлэл устгах </p>
                  <Form.Item name="news_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "news_delete")}
                      checked={checkedDatas?.news_delete}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Нийтлэл шинэчлэх </p>
                  <Form.Item name="news_edit">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "news_edit")}
                      checked={checkedDatas?.news_edit}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Тодорхой хэдэн мэдээ ангилал дээр үйлчлэх </p>
                  <Form.Item name="news_advanced">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => {
                        handleCheck(value, "news_advanced");
                        setCheckNewsCategories([]);
                      }}
                      checked={checkedDatas?.news_advanced}
                    />
                  </Form.Item>
                </div>
                {checkedDatas?.news_advanced && (
                  <div className="modal__item_list_box">
                    <Form.Item name="news_categories_check">
                      <Tree
                        blockNode
                        checkStrictly={true}
                        checkable
                        onCheck={(checkedKeysValue) =>
                          setCheckNewsCategories(checkedKeysValue.checked)
                        }
                        checkedKeys={checkNewsCategories}
                        treeData={newsCategories}
                      />
                    </Form.Item>
                  </div>
                )}

                {(checkedDatas?.news_add || checkedDatas?.news_edit) && (
                  <>
                    <div className="modal__item_box">
                      <p> Шууд нийтлэх эрх </p>
                      <Form.Item name="news_isActiveStatus">
                        <Switch
                          checkedChildren="Идэвхтэй"
                          unCheckedChildren="Идэвхгүй"
                          size="medium"
                          onChange={(value) => {
                            handleCheck(value, "news_isActiveStatus");
                          }}
                          checked={checkedDatas?.news_isActiveStatus}
                        />
                      </Form.Item>
                    </div>
                    <div className="modal__item_box">
                      <p> Ноороглох эрх </p>
                      <Form.Item name="news_isDraftStatus">
                        <Switch
                          checkedChildren="Идэвхтэй"
                          unCheckedChildren="Идэвхгүй"
                          size="medium"
                          onChange={(value) => {
                            handleCheck(value, "news_isDraftStatus");
                          }}
                          checked={checkedDatas?.news_isDraftStatus}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>

              <div className="modal__item">
                <label>Медиа контент</label>
                <div className="modal__item_box">
                  <p> Медиа нэмэх </p>
                  <Form.Item name="media_add">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "media_add")}
                      checked={checkedDatas?.media_add}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Медиа устгах </p>
                  <Form.Item name="media_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "media_delete")}
                      checked={checkedDatas?.media_delete}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Медиа шинэчлэх </p>
                  <Form.Item name="media_edit">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "media_edit")}
                      checked={checkedDatas?.media_edit}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Тодорхой хэдэн медиа контент ангилал дээр үйлчлэх </p>
                  <Form.Item name="media_advanced">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => {
                        handleCheck(value, "media_advanced");
                        setCheckMediaCategories([]);
                      }}
                      checked={checkedDatas?.media_advanced}
                    />
                  </Form.Item>
                </div>
                {checkedDatas?.media_advanced && (
                  <div className="modal__item_list_box">
                    <Form.Item name="news_categories_check">
                      <Tree
                        blockNode
                        checkStrictly={true}
                        checkable
                        treeData={mediaMenus}
                        onCheck={(checkedKeysValue) =>
                          setCheckMediaCategories(checkedKeysValue.checked)
                        }
                        checkedKeys={checkMediaCategories}
                      />
                    </Form.Item>
                  </div>
                )}
                {(checkedDatas?.media_add || checkedDatas?.media_edit) && (
                  <>
                    <div className="modal__item_box">
                      <p> Шууд нийтлэх эрх </p>
                      <Form.Item name="media_isActiveStatus">
                        <Switch
                          checkedChildren="Идэвхтэй"
                          unCheckedChildren="Идэвхгүй"
                          size="medium"
                          onChange={(value) => {
                            handleCheck(value, "media_isActiveStatus");
                          }}
                          checked={checkedDatas?.media_isActiveStatus}
                        />
                      </Form.Item>
                    </div>
                    <div className="modal__item_box">
                      <p> Шууд ноороглох эрх </p>
                      <Form.Item name="media_isDraftStatus">
                        <Switch
                          checkedChildren="Идэвхтэй"
                          unCheckedChildren="Идэвхгүй"
                          size="medium"
                          onChange={(value) => {
                            handleCheck(value, "media_isDraftStatus");
                          }}
                          checked={checkedDatas?.media_isDraftStatus}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>
              <div className="modal__item">
                <label>Хамтрагчид</label>
                <div className="modal__item_box">
                  <p> Хамтрагч нэмэх </p>
                  <Form.Item name="partner_add">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "partner_add")}
                      checked={checkedDatas?.partner_add}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Хамтрагч устгах </p>
                  <Form.Item name="partner_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "partner_delete")}
                      checked={checkedDatas?.partner_delete}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Хамтрагч шинэчлэх </p>
                  <Form.Item name="partner_edit">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "partner_edit")}
                      checked={checkedDatas?.partner_edit}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="modal__item">
                <label>Ажилчид</label>
                <div className="modal__item_box">
                  <p> Ажилчид нэмэх </p>
                  <Form.Item name="employee_add">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "employee_add")}
                      checked={checkedDatas?.employee_add}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Ажилчид устгах </p>
                  <Form.Item name="employee_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) =>
                        handleCheck(value, "employee_delete")
                      }
                      checked={checkedDatas?.employee_delete}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Ажилчид шинэчлэх </p>
                  <Form.Item name="employee_edit">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "employee_edit")}
                      checked={checkedDatas?.employee_edit}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Тодорхой хэдэн алба нэгж дээр үйлчлэх </p>
                  <Form.Item name="employee_advanced">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => {
                        handleCheck(value, "employee_advanced");
                        setCheckEmployee([]);
                      }}
                      checked={checkedDatas?.employee_advanced}
                    />
                  </Form.Item>
                </div>
                {checkedDatas?.employee_advanced && (
                  <div className="modal__item_list_box">
                    <Form.Item name="employee_categories_check">
                      <Tree
                        blockNode
                        checkStrictly={true}
                        checkable
                        treeData={positions}
                        onCheck={(checkedPosition) =>
                          setCheckEmployee(checkedPosition.checked)
                        }
                        checkedKeys={checkEmployee}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
              <div className="modal__item">
                <label>Номын сан</label>
                <div className="modal__item_box">
                  <p> Номын сан нэмэх </p>
                  <Form.Item name="book_add">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "book_add")}
                      checked={checkedDatas?.book_add}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Номын сан устгах </p>
                  <Form.Item name="book_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "book_delete")}
                      checked={checkedDatas?.book_delete}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Номын сан шинэчлэх </p>
                  <Form.Item name="book_edit">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "book_edit")}
                      checked={checkedDatas?.book_edit}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="modal__item">
                <label>Санал хүсэлт</label>
                <div className="modal__item_box">
                  <p> Санал хүсэлт харах </p>
                  <Form.Item name="contact_view">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "contact_view")}
                      checked={checkedDatas?.contact_view}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Санал хүсэлт устгах </p>
                  <Form.Item name="contact_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "contact_delete")}
                      checked={checkedDatas?.contact_delete}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="modal__item">
                <label>Ерөнхий тохиргоо</label>
                <div className="modal__item_box">
                  <p> Ерөнхий харах </p>
                  <Form.Item name="settings_view">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "settings_view")}
                      checked={checkedDatas?.settings_view}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Ерөнхий шинэчлэх </p>
                  <Form.Item name="settings_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) =>
                        handleCheck(value, "settings_delete")
                      }
                      checked={checkedDatas?.settings_delete}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="modal__item">
                <label>Сайтын цэс</label>
                <div className="modal__item_box">
                  <p> Сайтын цэс нэмэх </p>
                  <Form.Item name="siteMenu_add">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "siteMenu_add")}
                      checked={checkedDatas?.siteMenu_add}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Сайтын цэс устгах </p>
                  <Form.Item name="siteMenu_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) =>
                        handleCheck(value, "siteMenu_delete")
                      }
                      checked={checkedDatas?.siteMenu_delete}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Сайтын цэс шинэчлэх </p>
                  <Form.Item name="siteMenu_edit">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "siteMenu_edit")}
                      checked={checkedDatas?.siteMenu_edit}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="modal__item">
                <label>Сайтын хуудас</label>
                <div className="modal__item_box">
                  <p> Сайтын хуудас нэмэх </p>
                  <Form.Item name="sitePage_add">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "sitePage_add")}
                      checked={checkedDatas?.sitePage_add}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Сайтын хуудас устгах </p>
                  <Form.Item name="sitePage_delete">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) =>
                        handleCheck(value, "sitePage_delete")
                      }
                      checked={checkedDatas?.sitePage_delete}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p> Сайтын хуудас шинэчлэх </p>
                  <Form.Item name="sitePage_edit">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => handleCheck(value, "sitePage_edit")}
                      checked={checkedDatas?.sitePage_edit}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p>
                    Сайтын цэснүүдээс зөвхөн сонгодсон цэсэнд нэмэх боломжтой
                    болгох
                  </p>
                  <Form.Item name="sitePage_advancedMenu">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => {
                        handleCheck(value, "sitePage_advancedMenu");
                        setCheckMenu([]);
                      }}
                      checked={checkedDatas?.sitePage_advancedMenu}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p>
                    Сайтын хөлний цэснүүдээс зөвхөн сонгодсон цэсэнд нэмэх
                    боломжтой болгох
                  </p>
                  <Form.Item name="sitePage_advancedFooterMenu">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => {
                        handleCheck(value, "sitePage_advancedFooterMenu");
                        setCheckFooterMenu([]);
                      }}
                      checked={checkedDatas?.sitePage_advancedFooterMenu}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item_box">
                  <p>
                    Сайтын харьяалагдах хэлтэснүүдээс зөвхөн сонгодсон хэсэгт
                    нэмэх боломжтой болгох
                  </p>
                  <Form.Item name="sitePage_advancedEmployee">
                    <Switch
                      checkedChildren="Идэвхтэй"
                      unCheckedChildren="Идэвхгүй"
                      size="medium"
                      onChange={(value) => {
                        handleCheck(value, "sitePage_advancedEmployee");
                        setCheckPosition([]);
                      }}
                      checked={checkedDatas?.sitePage_advancedEmployee}
                    />
                  </Form.Item>
                </div>
                <div className="modal__item__categories_list">
                  {checkedDatas?.sitePage_advancedMenu && (
                    <div className="modal__item_category_item">
                      <label>Сайтын цэс</label>
                      <Tree
                        blockNode
                        checkStrictly={true}
                        checkable
                        treeData={menus}
                        onCheck={(checkedMenu) =>
                          setCheckMenu(checkedMenu.checked)
                        }
                        checkedKeys={checkMenu}
                      />
                    </div>
                  )}
                  {checkedDatas?.sitePage_advancedFooterMenu && (
                    <div className="modal__item_category_item">
                      <label>Сайтын хөлний цэс</label>
                      <Tree
                        blockNode
                        checkStrictly={true}
                        checkable
                        treeData={footerMenus}
                        onCheck={(checkedMenu) =>
                          setCheckFooterMenu(checkedMenu.checked)
                        }
                        checkedKeys={checkFooterMenu}
                      />
                    </div>
                  )}
                  {checkedDatas?.sitePage_advancedEmployee && (
                    <div className="modal__item_category_item">
                      <label>Алба нэгж</label>
                      <Tree
                        blockNode
                        checkStrictly={true}
                        checkable
                        treeData={positions}
                        onCheck={(checkedPosition) =>
                          setCheckPosition(checkedPosition.checked)
                        }
                        checkedKeys={checkPosition}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="modal__bottom">
              <Button
                loading={props.loading}
                key="submit"
                htmlType="submit"
                type="primary"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      handleAdd(values);
                    })
                    .catch((info) => {});
                }}
              >
                Нэмэх
              </Button>
              <Button onClick={() => props.history.goBack()}>Буцах</Button>
            </div>
          </Form>
          <div className="container-fluid"></div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.newsCategoryReducer.categories,
    mediaCategories: state.mediaCategoryReducer.categories,
    positions: state.positionReducer.positions,
    menus: state.menuReducer.menus,
    footerMenus: state.footerMenuReducer.footerMenus,
    success: state.roleReducer.success,
    error: state.roleReducer.error,
    loading: state.roleReducer.loading,
    roleDynamic: state.tokenReducer.roleDynamic,
    role: state.roleReducer.role,
    roleAccess: state.tokenReducer.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadNewsCagegories: () => dispatch(newsCategoryAction.loadNewsCategories()),
    loadMediaCategories: () =>
      dispatch(mediaCategoryAction.loadMediaCategories()),
    loadPositions: () => dispatch(positionAction.loadPositions()),
    loadMenus: () => dispatch(menuAction.loadMenus()),
    loadFooterMenus: () => dispatch(footerMenuActions.loadFooterMenus()),
    updateRole: (id, data) => dispatch(actions.updateRole(id, data)),
    getRole: (id) => dispatch(actions.getRole(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
